import { useMemo } from "react";
import TimezoneSelect, {
  ITimezone,
  useTimezoneSelect,
} from "react-timezone-select";

import { Alert, Badge, Button } from "flowbite-react";
import { DateTime } from "luxon";
import { useRouter } from "next/router";
import { useLocalStorage, useStep } from "usehooks-ts";

import { Routes } from "@helpers/routesSimple";
import { Icon } from "@riffs/Icon";
import { Modal } from "@riffs/Modal";
import { StyledText } from "@riffs/StyledText";
import {
  getBrowserTimeZone,
  getCurrentTimeZone,
  getTimeZoneAbbreviation,
} from "@util/dateHelpers";
import { ForteTimezones } from "@util/timezones";

import { useAuthedViewer } from "./AuthedViewerProvider";
import { useMaybeViewerContext } from "./MaybeViewerProvider";
import { useToast } from "./ToastProvider";

import { useUpdateUserPreferencesMutation } from "@graphql";

const useTimeZoneAcknowledgement = () => {
  /*
    acknowledgedBrowserTZ is used to track if the user has acknowledged that there is mismatch between
    their stored time zone preference and their browser. acknowledgedBrowserTZ should always equal the
    browser's current time zone or null. Once the user has set acknowledgedBrowserTZ the TimezoneUpdateModal
    will no longer be displayed. This way if the user's device/brower time zone ever changes the modal
    will trigger again and ask them to confirm the time zone.
  */
  const [acknowledgedBrowserTZ, setAcknowledgedBrowserTZ] = useLocalStorage<
    string | null
  >("acknowledgedBrowserTZ", null);

  return {
    acknowledgedBrowserTZ,
    setAcknowledgedBrowserTZ,
  };
};

export function TimeZoneUpdateModal() {
  const { viewer } = useAuthedViewer();
  const router = useRouter();
  const { refetchViewerContext } = useMaybeViewerContext();

  const browserTimeZone = getBrowserTimeZone();

  const { acknowledgedBrowserTZ, setAcknowledgedBrowserTZ } =
    useTimeZoneAcknowledgement();

  const timeZonePreference = viewer.userPreferences?.timezone;

  const [step, { setStep }] = useStep(2);

  const [updateUserPreferences, { error, loading }] =
    useUpdateUserPreferencesMutation();

  const showModal =
    acknowledgedBrowserTZ !== browserTimeZone &&
    browserTimeZone !== viewer.userPreferences?.timezone;

  if (
    !showModal ||
    !timeZonePreference ||
    router.route === Routes.SET_PASSWORD
  ) {
    return null;
  }

  return (
    <Modal>
      <Modal.Header>Which time zone should we use?</Modal.Header>
      <Modal.Body>
        {step === 1 && (
          <div className="flex flex-col w-full gap-4">
            <StyledText>
              <div>Your browser thinks the current date and time is:</div>
              <div className="flex flex-col gap-2">
                <TimezoneDisplay currentTimezone={browserTimeZone} />
                <Button
                  disabled={loading}
                  onClick={async () => {
                    await updateUserPreferences({
                      variables: {
                        input: {
                          userId: viewer.id,
                          timezone: browserTimeZone,
                        },
                      },
                    });
                    setAcknowledgedBrowserTZ(browserTimeZone);
                    refetchViewerContext();
                  }}
                >
                  Use {browserTimeZone}
                </Button>
              </div>
            </StyledText>
            <StyledText>
              <div>Your Forte settings think the current date and time is:</div>
              <div className="flex flex-col gap-2">
                <TimezoneDisplay currentTimezone={timeZonePreference} />
                <Button
                  onClick={() => setAcknowledgedBrowserTZ(browserTimeZone)}
                >
                  Keep {timeZonePreference}
                </Button>
              </div>
            </StyledText>
            <div className="flex flex-col w-full gap-3">
              <Button color="light" onClick={() => setStep(2)}>
                Select a different time zone
              </Button>
              {error && <TimezoneSubmissionError />}
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="flex flex-col gap-2">
            <TimezoneSelector />
            <Button onClick={() => setAcknowledgedBrowserTZ(browserTimeZone)}>
              Done
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export function TimezoneSelector() {
  const { viewer } = useAuthedViewer();

  const browserTimeZone = getBrowserTimeZone();
  const currentTimeZone = viewer.userPreferences?.timezone || browserTimeZone;

  return (
    <div className="flex flex-col w-full gap-2">
      <TimezoneDisplay currentTimezone={currentTimeZone} />
      <UserTimezoneDropdown />
    </div>
  );
}

export function TimezoneBadge() {
  const { viewer } = useAuthedViewer();
  const { parseTimezone } = useTimezoneSelect({
    timezones: ForteTimezones,
  });

  const currentTimezone = viewer.userPreferences?.timezone;

  const { label } = parseTimezone(currentTimezone || "");

  if (!label) {
    return null;
  }

  return <Badge>{label}</Badge>;
}

export function BrowserTimezoneBadge() {
  const browserTimeZone = getBrowserTimeZone();
  const { parseTimezone } = useTimezoneSelect({
    timezones: ForteTimezones,
  });

  const { label } = parseTimezone(browserTimeZone);

  if (!label) {
    return null;
  }

  return <Badge className="inline-block">{label}</Badge>;
}

/**
 * Uses getCurrentTimeZone and getTimeZoneAbbreviation to return the current time zone abbreviation, such as "EDT".
 */
export function getCurrentTimeZoneAbbreviation(
  userPreferencesTimezone: string | null | undefined,
): string {
  const { currentTimeZone } = getCurrentTimeZone(userPreferencesTimezone);
  return getTimeZoneAbbreviation(currentTimeZone);
}

export function UserTimezoneDropdown() {
  const [updateUserPreferences, { error, loading }] =
    useUpdateUserPreferencesMutation();

  const { refetchViewerContext } = useMaybeViewerContext();
  const { viewer } = useAuthedViewer();
  const { upsertNotification } = useToast();

  const { browserTimeZone, currentTimeZone } = getCurrentTimeZone(
    viewer.userPreferences?.timezone,
  );

  const { setAcknowledgedBrowserTZ } = useTimeZoneAcknowledgement();

  return (
    <>
      <TimezoneSelect
        className="text-sm mt-1"
        isDisabled={loading}
        value={currentTimeZone}
        timezones={{
          ...ForteTimezones,
        }}
        onChange={async (tz: ITimezone) => {
          const timezone = typeof tz === "string" ? tz : tz.value;

          // It can be assumed that when a user manually selects a time zone that they are acknowledging
          // that there is a mismatch between their stored preference and their browser.
          setAcknowledgedBrowserTZ(browserTimeZone);

          await updateUserPreferences({
            variables: {
              input: {
                userId: viewer.id,
                timezone,
              },
            },
          });

          refetchViewerContext();

          upsertNotification({
            displayType: "success",
            message: "Your time zone has been updated.",
          });
        }}
      />
      {error && <TimezoneSubmissionError />}
    </>
  );
}

function TimezoneSubmissionError() {
  return (
    <Alert color="error">There was an error updating your time zone.</Alert>
  );
}

function TimezoneDisplay({ currentTimezone }: { currentTimezone: string }) {
  const datetime = useMemo(
    () =>
      DateTime.fromJSDate(new Date(), {
        zone: currentTimezone,
      }).toFormat("DDDD t"),
    [currentTimezone],
  );
  return (
    <div className="flex flex-row font-bold w-full p-3 bg-forteBlue-20 border-2 border-forteBlue-40 rounded-md">
      <Icon.ClockOutline className="inline-block mr-1 dark:text-gray-700" />
      <StyledText className="dark:!text-gray-700">
        <div className="flex items-center">{datetime} </div>
        <div>{currentTimezone}</div>
      </StyledText>
    </div>
  );
}

/**
 * Uses getCurrentTimeZone and getTimeZoneAbbreviation to return a div with the current time zone abbreviation, such as "EDT".
 */
export function TimeZoneAbbreviated({ className = "" }) {
  const { viewer } = useMaybeViewerContext();

  const currentTimeZoneAbbreviation = getCurrentTimeZoneAbbreviation(
    viewer && viewer.userPreferences?.timezone,
  );
  return <div className={className}>{currentTimeZoneAbbreviation}</div>;
}

/**
 * Shows the viewer's current time zone abbreviation (such as "EDT") and also an Edit link that goes to Settings.
 */
export function TimeZoneLabel({
  outerClassName = "text-sm border border-gray-200 dark:border-gray-500 rounded-md p-2 inline-block mt-2 mx-auto",
  labelClassName = "inline font-bold",
  mainClassName = "inline",
  linkClassName = "inline pl-2 text-forteBlue dark:text-blue-400",
}) {
  return (
    <div className={outerClassName}>
      <StyledText secondary className={labelClassName}>
        Time zone: <TimeZoneAbbreviated className={mainClassName} />
      </StyledText>

      <a
        className={linkClassName}
        // TODO: Hash links like this won't scroll to the desired location until we use something like https://www.npmjs.com/package/react-router-hash-link but maybe for Next.js.
        href={`${Routes.SETTINGS}#time_zone`}
        target="_blank"
        rel="noreferrer"
      >
        Edit
        <Icon.OpenNewWindow className="inline align-bottom pl-1" />
      </a>
    </div>
  );
}
