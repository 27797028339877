import { type PropsWithChildren, useEffect } from "react";

import { clsx } from "clsx";
import { Card } from "flowbite-react";
import Link from "next/link";
import { useRouter } from "next/router";

import { FooterFrontDoorLayout } from "@components/FooterFrontDoorLayout";
import { MaybeImpersonationBanner } from "@components/Impersonation";
import { Invitation } from "@components/Invitation";
import { LogoWithCoBranding } from "@components/LogoWithCoBranding";
import { PREFIX_JOIN } from "@helpers/routes";
import { Routes } from "@helpers/routesSimple";
import { useLayoutData } from "@layouts/LayoutDataProvider";
import { StyledText } from "@riffs/StyledText";
import { useInvitation } from "@util/useInvitation";

export const FrontDoorLayout = ({
  children,
  heading,
  lowerLine,
  showInvitation = false,
}: PropsWithChildren & {
  heading?: string;
  lowerLine?: string;
  showInvitation?: boolean;
}) => {
  const router = useRouter();

  const { setInfoBlockHeading, setInfoBlockLowerLine } = useLayoutData();

  // InfoBlock-related
  useEffect(() => {
    setInfoBlockHeading(heading);
    setInfoBlockLowerLine(lowerLine);
  }, [
    heading,
    lowerLine,
    setInfoBlockHeading,
    setInfoBlockLowerLine,
    router.pathname, // ! Make sure this calls on every route change, otherwise the previous page's heading/lowerLine will persist!
  ]);

  const { invitationToken } = useInvitation();

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center min-h-screen pt-8 pb-4 px-4",
      )}
    >
      <MaybeImpersonationBanner />
      <div className="flex-grow flex flex-col justify-center w-full max-w-md">
        <LogoWithCoBranding />
        <Card className={clsx("w-full max-w-md mt-8")}>
          <div className={clsx()}>
            {showInvitation && invitationToken && (
              <Invitation invitationToken={invitationToken} />
            )}

            {children}

            {(router?.asPath?.includes(PREFIX_JOIN) ||
              router?.asPath?.includes(Routes.SIGN_IN)) && (
              <div className="flex-0 flex flex-col justify-center items-center mt-4">
                {router?.asPath?.includes(PREFIX_JOIN) && (
                  <StyledText size="sm">
                    Already have an account?{" "}
                    <Link
                      href={Routes.SIGN_IN}
                      className="underline hover:text-gray-200"
                    >
                      Sign In
                    </Link>
                  </StyledText>
                )}

                {router?.asPath?.includes(Routes.SIGN_IN) && (
                  <StyledText size="sm">
                    Don't have an account?{" "}
                    <Link
                      href={PREFIX_JOIN}
                      className="underline hover:text-gray-200"
                    >
                      Join Forte
                    </Link>
                  </StyledText>
                )}
              </div>
            )}
          </div>
        </Card>
      </div>

      <FooterFrontDoorLayout />
    </div>
  );
};
