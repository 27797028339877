import { type PropsWithChildren } from "react";

import { Button, Card } from "flowbite-react";
import Link from "next/link";

import { AddNewTeachingInstrument } from "@components/AddNewTeachingInstrument";
import { useMaybeViewerContext } from "@components/MaybeViewerProvider";
import { Routes } from "@helpers/routesSimple";
import { Avatar } from "@riffs/Avatar";
import { Heading } from "@riffs/Heading";
import { Modal } from "@riffs/Modal";
import { StyledText } from "@riffs/StyledText";

import { type RoleT, RoleTypeEnum } from "../../constants/role";

const roleLabelLookup = {
  [RoleTypeEnum.STUDENT_OR_PARENT]: "My parent/student account",
  [RoleTypeEnum.TEACHER]: "My teaching studios",
  [RoleTypeEnum.FORTE_ADMIN]: "ForteAdmin",
};

type RoleCardProps = {
  role: RoleT;

  roleButtonLabel: string;
};
const RoleCard: React.FC<RoleCardProps> = ({ role, roleButtonLabel }) => {
  const { updateCurrentRole } = useMaybeViewerContext();
  const roleLabel = roleLabelLookup[role.type];

  return (
    <Card>
      <div className="flex flex-row justify-between items-center gap-3">
        <div>
          <Heading level={6} className="mb-1">
            {roleLabel}
          </Heading>
          {role.type === RoleTypeEnum.STUDENT_OR_PARENT &&
            role.organizationName && (
              <StyledText size="sm">{role.organizationName}</StyledText>
            )}
        </div>
        <Button color="neon" onClick={() => updateCurrentRole(role)}>
          {roleButtonLabel}
        </Button>
      </div>
    </Card>
  );
};

type RoleCardContainerProps = PropsWithChildren;

const RoleCardContainer: React.FC<RoleCardContainerProps> = ({ children }) => {
  return <div className="flex flex-col gap-3">{children}</div>;
};

type SwitchRoleProps = {
  roleButtonLabel?: string;
};

export const SwitchRole: React.FC<SwitchRoleProps> = ({
  roleButtonLabel = "Go to Dashboard",
}) => {
  const { allRoles, refetchViewerContext, viewer } = useMaybeViewerContext();

  const studentRoles = allRoles.filter(
    ({ type }) => type === RoleTypeEnum.STUDENT_OR_PARENT,
  );
  const teacherRole = allRoles.find(
    ({ type }) => type === RoleTypeEnum.TEACHER,
  );
  const forteAdminRole = allRoles.find(
    ({ type }) => type === RoleTypeEnum.FORTE_ADMIN,
  );

  return (
    <main className="flex flex-col gap-6">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col sm:flex-row gap-3 items-center">
          <div className="flex-0">
            <Avatar img={viewer?.currentAvatar?.url} size="md" rounded />
          </div>
          <StyledText>{viewer?.givenNameAndInitialOfFamilyName}</StyledText>
        </div>
        <div>
          <Link
            href={{
              pathname: Routes.SIGN_OUT,
            }}
            className="underline text-blue-600"
          >
            Sign Out
          </Link>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <RoleCardContainer>
          {teacherRole && (
            <RoleCard role={teacherRole} roleButtonLabel={roleButtonLabel} />
          )}
          {!teacherRole && viewer?.isAdult && (
            <Card>
              <div className="flex flex-row justify-between items-center gap-3">
                <AddNewTeachingInstrument
                  onInstrumentCreated={() => refetchViewerContext()}
                />
              </div>
            </Card>
          )}
        </RoleCardContainer>

        <RoleCardContainer>
          {studentRoles.map(
            (role) =>
              role.type === RoleTypeEnum.STUDENT_OR_PARENT && (
                <RoleCard
                  key={role.organizationId}
                  role={role}
                  roleButtonLabel={roleButtonLabel}
                />
              ),
          )}
        </RoleCardContainer>

        {Boolean(forteAdminRole) && forteAdminRole && (
          <RoleCardContainer>
            <RoleCard role={forteAdminRole} roleButtonLabel={roleButtonLabel} />
          </RoleCardContainer>
        )}
      </div>
    </main>
  );
};

export function SwitchRoleAsModal({ onClose }: { onClose: () => void }) {
  return (
    <Modal onClose={onClose}>
      <Modal.Header onClose={onClose}>Switch Role</Modal.Header>
      <Modal.Body>
        <SwitchRole />
      </Modal.Body>
    </Modal>
  );
}
