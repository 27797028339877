// This file must import nothing other than config. Keep it simple so that scripts like `server/emails/testEmailsWithFakeData.ts` can work.
import {
  NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_URL,
} from "../constants/config";
import { LearningHubQueryParam as LearningHubQueryParameter } from "../constants/matching";
import { replaceTrailingSquareBracketsChunk } from "./stringHelper";

export enum StripeRoute {
  ONBOARDING = "onboarding",
  RETURN = "return",
}

export const LOCALHOST = "localhost";
export const DOMAIN = NEXT_PUBLIC_VERCEL_URL
  ? NEXT_PUBLIC_VERCEL_URL
  : LOCALHOST;
const protocol = ["production", "preview"].includes(NEXT_PUBLIC_VERCEL_ENV)
  ? "https://"
  : "http://";
const DEFAULT_LOCALHOST_PORT = "3000";
export const BASE_URL = `${protocol}${
  NEXT_PUBLIC_VERCEL_URL ?? `${LOCALHOST}:${DEFAULT_LOCALHOST_PORT}`
}`;

const ADMIN = "/admin";
const STUDENT = "/student";
const TEACHER = "/teacher";
export const ANONYMOUS_BROWSE = `/browse`;

/* eslint-disable @typescript-eslint/prefer-literal-enum-member */
export enum Routes {
  SIGN_IN = "/sign-in",
  SIGN_OUT = "/sign-out",
  SETTINGS = "/settings",
  NOT_FOUND = "/404",

  SET_PASSWORD = "/set-password",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  MUST_RESET_PASSWORD = "/must-reset-password",

  DOWNLOAD_RECORDING = "/download-recording",

  /** Maybe we'll want to move this to `/`. */
  STUDENT_LEARNING_HUB = `${STUDENT}`,
  STUDENT_SCHEDULE = `${STUDENT}/schedule`,
  STUDENT_RECORDINGS = `${STUDENT}/recordings`,
  STUDENT_MY_FAMILY = `${STUDENT}/my-family`,

  STUDENT_PAYMENT = `${STUDENT}/payment`,
  STUDENT_PAYMENT_RETURN = `${STUDENT}/payment/${StripeRoute.RETURN}`,

  TEACHER_BILLING = `${TEACHER}/billing`,
  TEACHER_BILLING_ONBOARDING = `${TEACHER}/billing/${StripeRoute.ONBOARDING}`,
  TEACHER_BILLING_RETURN = `${TEACHER}/billing/${StripeRoute.RETURN}`,

  TEACHER_PROFILE = `${TEACHER}/profile`,
  /** @deprecated use TEACHER_TEACHING_HUB */
  TEACHER_STUDENTS = `${TEACHER}/students`,
  TEACHER_TEACHING_HUB = `${TEACHER}/students`,

  ADMIN_EMAILS = `${ADMIN}/emails`,
  ADMIN_STUDIO = `${ADMIN}/studio`,
  ADMIN_STUDENTS = `${ADMIN}/students`,
  ADMIN_USERS = `${ADMIN}/users`,
  ADMIN_DAILY_DATA_LOG = `${ADMIN}/data-log`,
  ADMIN_TWILIO_LOG = `${ADMIN}/twilio-log`,
  ADMIN_CONNECT_TEST = `${ADMIN}/connect`,
  ADMIN_PENDING_PROFILES = `${ADMIN}/profiles`,
}
/* eslint-enable @typescript-eslint/prefer-literal-enum-member */

export const TEACHER_PROFILE_BY_TEACHEROF_ID = `${Routes.TEACHER_PROFILE}/[teacherOfId]`;

export const RouteQueryParams = {
  STUDENT_STUDIO: {
    REVIEW_TEACHER_ID: "reviewTeacherId",
  },
  LEARNING_HUB: {
    LEARNER_OF_ID: "learnerOfId",
  },
};

export const getStudentStudioPageForLearnerOf = (
  learnerOfId: null | string,
) => {
  if (!learnerOfId) {
    return Routes.STUDENT_LEARNING_HUB;
  }

  return `${Routes.STUDENT_LEARNING_HUB}?${LearningHubQueryParameter.ACTIVE_LEARNER_OF}=${learnerOfId}`;
};

export const generateTeacherResultsPathForAnonymousBrowsingMatchingRequestId = (
  matchingRequestId: string,
  queryParams?: Record<string, string>,
) => {
  const url = new URL(BASE_URL + ANONYMOUS_BROWSE + "/" + matchingRequestId);

  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      url.searchParams.set(key, value);
    }
  }

  return url.toString();
};

export const generateDirectTeacherUrl = (
  teacherOfId: string,
  queryParams?: Record<string, string>,
) => {
  const url = new URL(
    `${BASE_URL}${replaceTrailingSquareBracketsChunk(
      TEACHER_PROFILE_BY_TEACHEROF_ID,
      teacherOfId,
    )}`,
  );

  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      url.searchParams.set(key, value);
    }
  }

  return url.toString();
};
