import { useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";

import { ACCEPT_INVITATION, HELP, SIGN_IN, SIGN_OUT } from "@helpers/routes";
import { StyledText } from "@riffs/StyledText";
import { useInvitation } from "@util/useInvitation";

import { SessionStatus } from "./AuthGatekeeper";

const SignOutAndPreserveInvitationToken = () => {
  const { invitationToken } = useInvitation();

  return (
    <Link
      href={{
        pathname: SIGN_OUT,
        query: {
          callbackUrl: SIGN_IN, // Explicitly land the user on Sign In after they sign out
          invitationToken, // Keep invitationToken in the query so the user will be able to accept invitation as the user they switch to
        },
      }}
      className="underline"
    >
      Sign Out
    </Link>
  );
};

export const FooterFrontDoorLayout = () => {
  const router = useRouter();
  const { status } = useSession();

  return (
    <div className="text-forteCharcoal-40 text-sm">
      <div className="flex flex-col gap-4 py-6 mt-6">
        <StyledText className="text-center">
          All information entered into Forte is stored securely and will never
          be sold under any circumstance.
        </StyledText>

        {router?.asPath.includes(ACCEPT_INVITATION) &&
          status === SessionStatus.AUTHENTICATED && (
            <StyledText className="text-center">
              <SignOutAndPreserveInvitationToken />
            </StyledText>
          )}

        <StyledText secondary className="text-center">
          {`Problems with your account? Go to the `}
          <Link href={HELP} className="underline" target="_blank">
            Help Center
          </Link>
        </StyledText>
      </div>
    </div>
  );
};
